import { TextField } from "@material-ui/core";
import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";

interface InputPhone {
    value: string;
    onChange: (e: any) => void;
}
const InputPhone = (props: any, ref: any) => {
    const {t} = useTranslation();
    return (
        <TextField 
            {...props}
            inputRef={ref}
            fullWidth
            label={t('phone')}
            variant='filled'
            color='secondary'
            name='phone'
        />
    )
}

export default forwardRef(InputPhone)
