export interface ITransaction<T> {
  type: TransactionEnum;
  item: string;
  company: string;
  user: string;
  data: T;
  createdAt: Date;
}

export interface IMessage {
  type: string;
  userName: string;
  userEmail: string;
  link?: string;
}

export interface IMessageStore extends IMessage {
  read: boolean;
}

export enum NotificationTypes {
  STOCKTAKING_FINISHED = "0",
}

export enum PaymentMethod {
  SUBSCRIPTION = "subscription",
  FIXED = "fixed",
  INVOICE = "invoice",
}

export enum PaymentMethodType {
  REGULAR = "regular",
  SUBSCRIPTION = "subscription",
}
export enum SubscriptionStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export interface ITransactionFull<T> {
  _id: string;
  type: TransactionEnum;
  item: IItem;
  company: ICompany;
  user: IUser;
  data: T;
  error?: {
    type: ITxErrorTypes;
    message: string;
  };
  createdAt: Date;
}

export enum ITxErrorTypes {
  ACCESS = "AccessError",
}

export enum TransactionEnum {
  ADD = 0,
  MARKING = 1,
  TRANSFER = 2,
  REPAIR = 3,
  BAN = 4,
  TRANSFER_RETURN = 5,
  MOUNT = 6,
  UNMOUNT = 7,
  CHANGE_QUANTITY = 8,
  CHANGE_PRICE = 9,
  CHANGE_PRICE_TOTAL = 10,
  ITEM_SEPARATION = 11,
  ITEM_MERGING = 12,
  ADD_TO_TRANSFER = 13,
  REMOVE_FROM_TRANSFER = 14,
  CHANGE_LOCATION = 15,
}

export const TransactionNames = [
  "add",
  "marking",
  "transfer",
  "service",
  "ban",
  "return_service",
  "mount",
  "unmount",
  "change_quantity",
  "change_price_total",
  "separate",
];

export enum TransferStatuses {
  COMPLETE = "complete",
  PENDING = "pending",
  REJECTED = "rejected",
}

export interface IUser {
  _id: string;
  firstName: string;
  lastName: string;
  role: Roles;
  email: string;
  email_approved: boolean;
  phone_approved: boolean;
  password: string;
  phone: string;
  company?: string;
  createdAt: Date;
  is_system?: boolean;
  updatedAt?: Date;
  lastSmsSentTime?: Date;
  code: string;
  metadata: {
    integrations: any;
  };
  ids_integration: any;
}

export interface IUserFull {
  _id: string;
  firstName: string;
  lastName: string;
  role: Roles;
  email: string;
  email_approved: boolean;
  phone_approved: boolean;
  password: string;
  phone: string;
  company?: ICompany;
  createdAt: Date;
  lastSmsSentTime?: Date;
  updatedAt?: Date;
  itemsCount?: number;
  code: string;
  metadata: {
    integrations: any;
  };
  ids_integration: any;
}

export interface IPlan {
  _id: string;
  title: string;
  price: number;
  users: number;
  items: number;
  additional?: string;
  popular?: boolean;
  permissions: string[];
}

export interface ICompany {
  _id: string;
  title: string;
  description: string;
  owner: string;
  usersLimit: number;
  itemsLimit: number;
  subscriptionEndDate: Date;
  is_ban: boolean;
  settings: ICompanySettings;
  plan: IPlan;
  payment: IPayment;
  currency: Currency;
}

export interface ICompanyFull {
  _id: string;
  title: string;
  description: string;
  owner: IUser;
  usersLimit: number;
  itemsLimit: number;
  subscriptionEndDate: Date;
  is_ban: boolean;
  settings: ICompanySettings;
  plan: IPlan;
  payment: IPayment;
  currency: Currency;
}

export interface ICompanySettings {
  operationsWithApprovement: boolean;
  promo?: { code: string; sale: number };
}
export interface ICustomField {
  label: string;
  value: string;
}

export interface IQuantity {
  quantity: number;
  units: string;
  parent?: string;
  ancestors: string[];
}

export interface IQuantityFull {
  quantity: number;
  units: string;
  parent?: IItem;
  ancestors: IItem[];
}

export interface IItem {
  _id: string;
  title: string;
  metadata: ItemMetadata;
  batch?: IQuantity;
  customFields?: ICustomField[];
  is_ban: boolean;
  is_marked: boolean;
  code?: string;
  repair: string;
  transfer: string;
  person: string;
  company: string;
  responsible: string;
  items: string[];
  parent: string;
  photos: { name: string; url: string }[];
  createdAt: Date;
  updatedAt: Date;
}

export interface IItemFull {
  _id: string;
  title: string;
  metadata: ItemMetadataFull;
  customFields?: ICustomField[];
  batch?: IQuantity;
  is_ban: boolean;
  is_marked: boolean;
  code?: string;
  repair: string;
  transfer: string;
  person: IUser;
  responsible: IUser;
  company: ICompany;
  items: IItem[];
  parent: IItem;
  photos: { name: string; url: string; thumb: string }[];
  ids_integration: any;
  createdAt: Date;
  updatedAt: Date;
}

export interface ItemMetadata {
  is_system?: boolean;
  type?: string;
  brand?: string;
  model?: string;
  serial?: string;
  capacity?: string;
  title?: string;
  location?: string;
  object?: string;
}

export interface ItemMetadataFull {
  type?: string;
  brand?: string;
  model?: string;
  serial?: string;
  capacity?: string;
  title?: string;
  countComments?: number;
  location?: string;
  object?: string;
  price?: string;
}

export interface IItemComment {
  _id: string;
  user: string;
  message: string;
  photos: { name: string; url: string }[];
  createdAt: Date;
}
export interface IItemCommentFull {
  _id: string;
  user: IUser;
  photos: { name: string; url: string }[];
  message: string;
  createdAt: Date;
}
export interface IBrand {
  _id: string;
  title: string;
  models: string[];
  company: string;
}
export interface ISubLocation {
  id: string;
  title: string;
}
export interface ILocationData {
  _id: string;
  title: string;
  locations: ISubLocation[];
  company: string;
  is_system?: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface ILocation {
  location_id: any;
  location: string;
}

export interface ITransfer {
  _id: string;
  status: TransferStatuses;
  sender: ITransferUser;
  recipient: ITransferUser;
  company: string;
  items: string[];
  createdAt: Date;
  updatedAt: Date;
}

export interface ITransferUser {
  role: Roles;
  _id: string;
  firstName: string;
  lastName: string;
  email?: string;
}

export enum Roles {
  ROOT = "root",
  ADMIN = "admin",
  STOCKMAN = "stockman",
  WORKER = "worker",
}

export enum IStocktakingStatuses {
  PENDING = "pending",
  COMPLETED = "completed",
  DENIED = "denied",
}
export interface IStocktakingProcessedItem {
  person: {
    _id: string;
    firstName: string;
    lastName: string;
    email: string;
    role: Roles;
  };
  item: {
    _id: string;
    type?: string;
    brand?: string;
    model?: string;
    serial?: string;
    capacity?: string;
    code?: string;
  };
  amountReal: number;
  amountStock: number;
}

export interface IStocktaking {
  _id: string;
  user: string;
  target: string;
  company: string;
  status: IStocktakingStatuses;
  items: IStocktakingProcessedItem[];
  createdAt: Date;
  updatedAt: Date;
}

export interface IStocktakingFull {
  _id: string;
  user: IUser;
  target: IUser;
  company: ICompany;
  status: IStocktakingStatuses;
  items: IStocktakingProcessedItem[];
  createdAt: Date;
  updatedAt: Date;
}

export const translateRole = (role: Roles | string) => {
  switch (role) {
    case Roles.ROOT:
      return "Владелец";
    case Roles.ADMIN:
      return "Администратор";
    case Roles.WORKER:
      return "Рабочий";
    case Roles.STOCKMAN:
      return "Кладовщик";
    default:
      return role;
  }
};

export enum MarkMethod {
  STICKER_LASER = "laser", // Наклейка 3М лазерная
  PLASTIC_SEAL = "plastic_seal", // Пломба пластик
  STICKER = "sticker",
  POLYMER = "polymer", // Полемерная наклейка
  SEAL = "seal", // Обычная пломба
}

export enum CodeRequestStatus {
  LOCAL = -1,
  NEW = 0,
  IN_PROGRESS = 1,
  DONE = 2,
  REJECTED = 3,
  SAVED = 4,
}

export const RequestStatus = [
  "new",
  "in_progress",
  "done",
  "rejected",
  "saved",
];

export interface IRequestCodeData {
  count: number;
  type: MarkMethod;
}

export interface ICodeRequest {
  _id: string;
  company: string;
  status: CodeRequestStatus;
  codes: IRequestCodeData[];
  deliveryData: any;
  key: string;
  user: string;
  createdAt: Date;
  updatedAt: Date;
}

export enum DatafileStatus {
  PROCESSING = 0,
  DONE = 1,
}

export interface IDatafile {
  _id: string;
  filename: string;
  company: string;
  user: IUser;
  status: DatafileStatus;
  error?: {
    message: string;
    name: string;
    row?: number;
  };
  length: number;
  createdAt: Date;
  updatedAt: Date;
}

export enum PaymentType {
  PLAN = "plan",
  STICKER = "request",
}

export interface IPaymentRequest {
  amount: number;
  signature: string;
  currency: "USD" | "UAH";
  data: any;
}

export enum PaymentDuration {
  MONTHLY = 1,
  HALF_YEAR = 6,
  YEAR = 12,
}

export enum PaymentStatus {
  CREATED = "created",
  PROCESSING = "processing",
  DECLINED = "declined",
  APPROVED = "approved",
  EXPIRED = "expired",
  REVERSED = "reversed",
}

export interface IPayment {
  _id: string;
  title: string;
  type: PaymentType;
  duration: PaymentDuration;
  method: PaymentMethod;
  status: PaymentStatus;
  paymentMethod: PaymentMethodType;
  subscriptionStatus: SubscriptionStatus;
  code: string;
  company: string;
  user: string;
  request: IPaymentRequest;
  response: any;
  payment_id: string;
  checkoutUrl: string;
  error: string[];
  createdAt: Date;
  updatedAt: Date;
}

export enum IntegrationTypes {
  ONE_C = "1C",
}

export const IntegrationPermission = [
  "download_sync",
  "transfer",
  "stocktaking",
  "writeoff",
];

export enum IntegrationLogEvent {
  INIT = 0,
  REPLACEMENT = 1,
  WRITE_OFF = 2,
  STOCKTAKING = 3,
  DELETE = 4,
  ADD = 5,
  AUTH = 6,
}

export interface IIntegrationLog {
  _id: string;
  type: number;
  operation: IntegrationLogEvent;
  data: { req: any; res: any };
  company: string;
  integration: string;
  createdAt: Date;
  updatedAt: Date;
}

export type Permission =
  | "storage"
  | "analytics"
  | "extended_search"
  | "scan"
  | "generator"
  | "transfer"
  | "service"
  | "ban"
  | "objects"
  | "stocktaking"
  | "equipment"
  | "custom_fields"
  | "items_upload"
  | "datatable_custom"
  | "reports"
  | "files"
  | "roles_extended"
  | "1C";

export const PermissionsSoon = ["roles_extended", "datatable_custom"];
export const Permissions: {
  [key: string]: Permission;
} = {
  STORAGE: "storage",
  ANALYTICS: "analytics",
  EXTENDED_SEARCH: "extended_search",
  SCAN: "scan",
  GENERATOR: "generator",
  TRANSFER: "transfer",
  SERVICE: "service",
  BAN: "ban",
  OBJECTS: "objects",
  EQUIPMENT: "equipment",
  STOCKTAKING: "stocktaking",
  CUSTOM_FIELDS: "custom_fields",
  ITEMS_UPLOAD: "items_upload",
  DATATABLE_CUSTOM: "datatable_custom",
  REPORTS: "reports",
  FILES: "files",
  ROLES_EXTENDED: "roles_extended",
};

export enum Currency {
  UAH = "UAH",
  RUB = "RUB",
  KZT = "KZT",
  USD = "USD",
  EU = "EU",
  PLN = "PLN",
}
