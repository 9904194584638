import React, {PropsWithChildren, useCallback, useEffect, useState} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {useDispatch, useMappedState} from "redux-react-hook";
import {SettingsActions} from "@core/store/actions/settings";
import {useTranslation} from "react-i18next";
import useDropdown from "@core/hooks/useDropdown";
import Sidebar from "./Sidebar";
import ToolbarCustom from "./Toolbar";
import {findRouteByPath} from "@core/routes";
import {AuthAction} from "@core/store/actions/auth";
import {TopbarContext} from "@core/context";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    marginTop: 64,
    [theme.breakpoints.down('sm')]: {
      padding: 0
    }
  },
  contentWithOpenMenu: {
    width: `calc(100% - 240px)`
  },
  doubletopbar: {
    marginTop: 120,
    boxSizing: "border-box"
  }
}));


interface IProps extends PropsWithChildren<RouteComponentProps<any>>{
  path: string,
  disabled: boolean
}


const TopbarWithNav = (props: IProps) => {
  const {path} = props;
  const {t} = useTranslation();
  const [title, setTitle] = useState<any>();
  const [topActions, setTopActions] = useState<any>();
  const classes = useStyles();
  const dispatch = useDispatch();
  const {open, lang} = useMappedState((state) => ({
    open: state.settings.menuOpen,
    lang: state.settings.language,
  }));

  useEffect(() => {
    setTitle([t(findRouteByPath(path))]);
  }, [path])

  useEffect(() => {
    setTopActions(null);
  }, [])

  const setPageName = useCallback((page: string) => {
    setTitle([t(findRouteByPath(path)), page])
  }, [title, path, t]);

  const setOpen = useCallback((value: boolean) => {
    dispatch({
      type: SettingsActions.SET_SETTINGS,
      payload: {
        menuOpen: value
      }
    })
  }, [dispatch]);

  const handleDrawerToggle = useCallback(() => {
    setOpen(!open);
  }, [open, setOpen]);

  const user = useMappedState((state) => state.auth.user);

  const logout = () => {
    localStorage.removeItem('token');
    props.history.push('/auth/login');
    setTimeout(() => {
      dispatch({
        type: AuthAction.CLEAR_AUTH
      })
    },0)
  };
  const dropdown = useDropdown({
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right',
    }
  });

  const changeLanguage = useCallback((lang: string) => {
    return () => {
      localStorage.setItem('lang', lang);
      dropdown.handleClose();
      dispatch({
        type: SettingsActions.CHANGE_LANG,
        payload: lang
      })
    }
  }, [dispatch, dropdown])


  return (
    <div className={classes.root}>
      <CssBaseline />
      <ToolbarCustom open={open} breadcrumbs={title} actions={topActions} changeLanguage={changeLanguage} handleDrawerToggle={handleDrawerToggle} path={path} lang={lang}/>
      <Sidebar open={open} logout={logout} user={user} location={props.location} disabled={props.disabled}/>
      <main className={
        clsx(
          classes.content,
          'content',
          {
            [classes.doubletopbar]: !!topActions,
            [classes.contentWithOpenMenu]: open
          },
        )
      }
      >
        <TopbarContext.Provider value={{setPageName, setTopActions}}>
          {props.children}
        </TopbarContext.Provider>
      </main>
    </div>
  );
};
export default withRouter(TopbarWithNav);
