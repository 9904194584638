import { IInputComponentProps } from '@core/hooks/useInput';
import { statsSelector } from '@core/store/selectors/config';
import React, { useCallback, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input'
import { useMappedState } from 'redux-react-hook';
import InputPhone from './InputPhone';
import './InputPhone.scss'


const Phone = (props: IInputComponentProps) => {
    const {value, setValue} = props;
    const { country, countryCodes } = useMappedState(statsSelector);
    const [currentCountryCode, setCurrentCountryCode] = useState<any>('UA');

    const handleChange = useCallback((newValue: string = '') => {
        setValue(newValue);
    }, [setValue]);
    return (
        <PhoneInput 
            defaultCountry={country}
            international
            value={value}
            inputComponent={InputPhone as any}
            onChange={handleChange}
        />
    )
}

export default Phone;