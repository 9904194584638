import {ITypedAction} from "../actions";
import {StocktakingActions} from "../actions/stocktaking";


const initialState: any = {
  data: [],
  filtersOpen: false,
  filters: {
    responsible: {title: '', value: ''},
    target: {title: '', value: ''},
    date: null
  },
  pagination: {
    limit: 5,
    offset: 0
  },
  total: 0,
  loading: 'default',
  item: null
};

const StocktakingReducer = (state = initialState, action: ITypedAction<StocktakingActions>) => {
  switch (action.type) {
    case StocktakingActions.SET_ITEMS:
      return {
        ...state,
        data: action.payload.data,
        total: action.payload.total
      };
    case StocktakingActions.SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload
        }
      };
    case StocktakingActions.CLEAR_FILTERS:
      return {
        ...state,
        filters: initialState.filters
      };
    case StocktakingActions.DELETE_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload]: initialState.filters[action.payload]
        }
      }
    case StocktakingActions.TOGGLE_FILTERS:
      return {
        ...state,
        filtersOpen: action.payload
      }
    case StocktakingActions.SET_PAGINATION:
      return {
        ...state,
        pagination: action.payload
      };
    case StocktakingActions.SET_ITEM:
      return {
        ...state,
        item: action.payload
      };
    case StocktakingActions.UPDATE_ITEM:
      return {
        ...state,
        item: {
          ...state.item,
          ...action.payload
        }
      };
    case StocktakingActions.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case StocktakingActions.CLEAR_ITEM:
      return {
        ...initialState
      };
    default:
      return {
        ...state
      }
  }
};

export default StocktakingReducer;
