import axios from './../utils/axios'

export interface ILoginRequest {
  email: string;
  password: string;
}

export interface IRegisterRequest extends ILoginRequest {
  firstName: string,
  lastName: string,
  phone?: string
}

export interface ChangePassword {
  current: string,
  newPassword: string
}

export default {
  login: async (body: ILoginRequest) => (await axios.post('/auth/login', body)).data,
  register: async (body: IRegisterRequest) => (await axios.post('/auth/register', body)).data,
  isLoggedIn: () => !!localStorage.getItem('token'),
  setUserToken: (token: string) => {
    localStorage.setItem('token', token);
    return true;
  },
  setFirebaseToken: async (token: string) => (await axios.patch('/auth/web/firebase', {token})),
  checkEmail: async (email: string) => (await axios.get('/auth/checkEmail', {params: {email}})).data,
  checkToken: async (token: string) => (await axios.get('/auth/checkToken', {params: {token}})).data,
  me: async () => (await axios.get('/users/me')).data,
  finishRegistration: async (token: string, password: string) => (await axios.post('/auth/finish', {password}, {params: {token}})).data,
  settings: async (id: string) => (await axios.get(`/company/${id}/settings`)).data,
  changePassword: async (data: ChangePassword) => (await axios.patch(`/auth/change/password`, data)),
  logout: () => localStorage.removeItem('token'),
  editSettings: async (id: string, body: {operationWithApprovement: boolean}) => (await axios.put(`/company/${id}/settings`, body)).data,
  createCompany: async (body: {title: string, description: string}) => (await axios.post(`/company`, body)),
  approveEmail: async (token: string) => (await axios.get('/auth/accept', {params: {token}})),
  forgotPassword: async (key: string) => (await axios.post('/auth/forgot', {key})).data,
  sendEmailVerification: async (id: string) => (await axios.post(`/auth/${id}/send_email`, {})).data,
  verifyPhone: async (token: string, phone: string)=> (await axios.post('/auth/send_sms', {token, phone})).data,
  checkVerificationCode: async (token: string, code: string)=> (await axios.post('/auth/verify/phone', {token, code})).data,
  checkPassword: async (password: string) => (await axios.post('/auth/check/password', {password})).data,
  googleLogin: async (token: string, options?: any) => (await axios.post('/auth/google', {token, ...options})).data
}
