import React, {useCallback} from "react";
import {Button, Divider, Icon, IconButton, List, ListItem, ListItemText} from "@material-ui/core";
import {nav} from "@core/routes";
import {Link, withRouter} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import UserInfo from "../../../Widgets/User/UserInfo";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {makeStyles} from "@material-ui/core/styles";
import classNames from "classnames";
import AccentButton from "@shared/Common/Button/AccentButton";
import GAEvent, {createEvent} from "@shared/Common/GAEvent";
import {img} from "@core/utils/string";
import Helmet from "react-helmet";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  navlist: {
    padding: '10px 10px',
  },
  bottomNav: {
    paddingBottom: 0
  },
  navitem: {
    padding: '10px 10px',
    borderRadius: 3,
    alignItem: 'center',
    marginBottom: 5
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    overflow: "hidden",
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: theme.palette.primary.main + ' !important',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 50,
    backgroundColor: theme.palette.primary.main + ' !important',
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 0px',
    borderRadius: '0px 5px 0px 0px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    ...theme.mixins.toolbar,
  },
  active: {
    backgroundColor: theme.palette.primary.light,
  },
  imageBlock: {
    display: 'flex',
    marginTop: '25px',
    marginBottom: '25px',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    flexGrow: 1,
    [theme.breakpoints.down(1400)]: {
      display: 'flex',
      flexDirection: 'column',
    }
  },
  appsImg: {
    width: '50%',
    [theme.breakpoints.down(1400)]: {
      width: '100%',
      marginBottom: '10px',
    }
  },
  img: {
    width: '80%',
    display: 'block',
    margin: '0 auto'
  },
  support: {
    padding: 20,
    color: '#ffffff',
    maxWidth: 210,
    borderRadius: 10,
    whiteSpace: 'pre-wrap',
    overflow: "hidden",
    '& p': {
      color: '#f4f4f4',
      fontSize: 13,
      marginBottom: 15,
      marginTop: 5
    },
    '& a': {
      fontSize: 13
    },
  },
  supportHidden: {
    display: 'none'
  },
  telegram: {
    display: 'flex',
    alignItems: 'center',
    color: '#439be5',
    fontWeight: 500,
    textTransform: 'uppercase',
    '& img': {
      marginRight: 10,
    }
  }
}));

const Sidebar = (props: any) => {
  const {open, user, location, disabled, history} = props;
  const {t} = useTranslation();
  const classes = useStyles();

  const navigate = useCallback((e: any, path: string, event: {category: string, action: string}) => {
    e.preventDefault();
    e.stopPropagation();
    createEvent(event);
    history.push(path);
  }, [history]);

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >

        <Link to="/settings" className={classes.toolbar}>
          <GAEvent category="Header" action="HeaderUserName">
            <UserInfo image={user.image} name={!user.firstName && !user.lastName ? user.email : `${user.firstName || ''} ${user.lastName || ''}`} email={user.email}/>
          </GAEvent>
          <GAEvent category="Header" action="HeaderThreeDots">
            <IconButton><Icon style={{color: "#fff"}}>more_vert</Icon></IconButton>
          </GAEvent>
        </Link>


      <List className={classes.navlist}>
        {nav.filter(i => i.location !== 'bottom').map((item) => {
          const haveAccess = item.root ? user.role === 'root' || user.role === 'admin' : true;
          const dontHaveAccess = item.excludedRoles ? item.excludedRoles.includes(user.role) : false;
          return (
            !item.hidden && haveAccess && !dontHaveAccess && <div onClick={(e: any) => {navigate(e, !disabled ? item.path : '/', item.event)}}  key={item.path}>
              <ListItem
                button
                disabled={props.disabled && item.path !== '/'}
                key={item.path}
                className={clsx(classes.navitem, {[classes.active]: location.pathname.indexOf(item.path) !== -1})}
              >
                <ListItemIcon
                  style={{minWidth: 40}}
                >
                  <Icon style={{color: '#fff', fontSize: 18}}>{item.icon}</Icon>
                </ListItemIcon>
                {open && <ListItemText primary={t(item.name)} className="small" style={{color: '#fff'}}/>}
              </ListItem>
            </div>
          )
        })}
      </List>
      <Divider style={{backgroundColor: '#525c6d'}}/>

      <div className={classes.imageBlock}>
        <div>
          <List className={classNames(classes.navlist, classes.bottomNav)}>
            {open && <ListItem>
              <GAEvent category="LeftSideMenu" action="LeftSideMenyBuy">
                <Link to="/payment" style={{margin: '0 auto'}}>
                  <AccentButton variant="contained">{t('buy_service')}</AccentButton>
                </Link>
              </GAEvent>
            </ListItem>}
            {nav.filter(i => i.location === 'bottom').map((item) => {
              const haveAccess = item.root ? user.role === 'root' || user.role === 'admin' : true;
              const dontHaveAccess = item.excludedRoles ? item.excludedRoles.includes(user.role) : false;
              return (
                !item.hidden && haveAccess && !dontHaveAccess && <div onClick={(e: any) => {navigate(e, !disabled ? item.path : '/', item.event)}}  key={item.path}>
                  <ListItem
                    button
                    disabled={props.disabled && item.path !== '/'}
                    key={item.path}
                    className={clsx(classes.navitem, {[classes.active]: item.path === location.pathname})}
                  >
                    <ListItemIcon
                      style={{minWidth: 40}}
                    >
                      <Icon style={{color: '#fff', fontSize: 18}}>{item.icon}</Icon>
                    </ListItemIcon>
                    {open && <ListItemText primary={t(item.name)} className="small" style={{color: '#fff'}}/>}
                  </ListItem>
                </div>
              )
            })}
          </List>
        </div>
        <div className={clsx(classes.support, {
          [classes.supportHidden]: !open
        })}>
          <h3 className="text-default">{t('support')}</h3>
          <p className="subtitle">{t('support_desc')}</p>
          <a href="tg://resolve?domain=Kirill_Prianykov_bot&start=ml2" id="telegram_bot" className={classes.telegram}>
            <img src="./images/telegram-icon.png" alt="" style={{width: '20px', height: 'auto'}}/>
            <span>Telegram Bot</span>
          </a>
          <br/>
          <a href="tel:+380669619008" className="link" style={{color: "#4dcc6d"}}>+38 (066) 961 90 08</a>
          <br/>
          <p style={{fontSize: 12}}>Telegram, Viber, WhatsApp</p>
          <a href="mailto:support@eqman.co" className="link" style={{color: "#4dcc6d"}}>support@eqman.co</a>
        </div>
        <div style={{display: "flex", justifyContent: "space-around"}}>
          <div className={classes.appsImg}>
            <a href="https://apps.apple.com/ua/app/equipmentman/id1504448666" target="_blank" rel="noopener noreferrer">
              <img src={img('apple.png')} alt="" className={classes.img}/>
            </a>
          </div>
          <div className={classes.appsImg}>
            <a href="https://play.google.com/store/apps/details?id=qrsmarty.eqman" target="_blank" rel="noopener noreferrer">
              <img src={img('android.png')} alt="" className={classes.img}/>
            </a>
          </div>
        </div>
      </div>
    </Drawer>
  )
}

export default withRouter(Sidebar)
