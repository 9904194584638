export enum IntegrationActions {
  GET_ITEMS = 'get_integrations',
  GET_ITEM = 'get_integration',
  SET_ITEMS = 'set_integrations',
  GET_LOGS = 'get_integration_logs',
  SET_LOGS = 'set_integration_logs',
  SET_LOADING = 'set_integrations_loading',
  SET_ITEM = 'set_integration',
  CLEAR_ITEM = 'clear_integration',
  SET_PAGINATION = 'set_pagination_integration',
  CLEAR_ITEMS = 'clear_integrations',
  CREATE_INTEGRATION = 'create_integration'
}