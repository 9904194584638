import {IAction} from "@core/store/actions";
import {IntegrationActions} from "@core/store/actions/integration";

const initialState = {
  data: null,
  loading: true,
  item: null,
  logs: null,
  total: 0,
  pagination: {
    limit: 20,
    offset: 0
  }
}

const IntegrationReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case IntegrationActions.SET_ITEMS:
      return {
        ...state,
        data: action.payload.data,
      };
    case IntegrationActions.SET_PAGINATION:
      return {
        ...state,
        pagination: action.payload
      };
    case IntegrationActions.SET_ITEM:
      return {
        ...state,
        item: action.payload
      };
    case IntegrationActions.SET_LOGS:
      return {
        ...state,
        logs: [...(state.logs || []), ...action.payload.data],
        total: action.payload.total
      };
    case IntegrationActions.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case IntegrationActions.CLEAR_ITEM:
      return {
        ...state,
        item: null,
        logs: null,
        pagination: initialState.pagination,
        total: 0
      };
    case IntegrationActions.CLEAR_ITEMS:
      return initialState;
    default:
      return state;
  }
};

export default IntegrationReducer;