import {all, put, takeLatest, select} from "redux-saga/effects";
import {IntegrationActions} from "@core/store/actions/integration";
import {IAction, IActionWithSingleSideEffect} from "@core/store/actions";
import Service from '@core/services/integration';
import notify from "@core/utils/notify";

function* getAll(action: IAction) {
  try {
    yield put({
      type: IntegrationActions.SET_LOADING,
      payload: true
    });

    const items = yield Service.getAll();

    yield put({
      type: IntegrationActions.SET_ITEMS,
      payload: {
        data: items
      }
    });

    yield put({
      type: IntegrationActions.SET_LOADING,
      payload: false
    });
  } catch(E) {
    yield put({
      type: IntegrationActions.SET_ITEMS,
      payload: {
        data: [],
        total: 0
      }
    });
  }
}

function* getSingle(action: IActionWithSingleSideEffect) {
  try {
    yield put({
      type: IntegrationActions.SET_LOADING,
      payload: true
    });

    yield put({
      type: IntegrationActions.SET_ITEM,
      payload: action.payload
    })

    if(action.payload.item) {
      yield put({
        type: IntegrationActions.GET_LOGS
      })
    }
    yield put({
      type: IntegrationActions.SET_LOADING,
      payload: false
    });

  } catch (e) {
    yield put({
      type: IntegrationActions.SET_LOADING,
      payload: false
    });
    notify.warning('Error getting integration details')
  }
}

function* getLogs(action: IAction) {
  try {
    const {integration: {pagination, item}} = yield select();
    if(item && item.item) {
      yield put({
        type: IntegrationActions.SET_LOADING,
        payload: true
      });
      const logs = yield Service.getById(item.item._id, pagination);
      yield put({
        type: IntegrationActions.SET_LOGS,
        payload: {
          data: logs.data,
          total: logs.count
        }
      })
      yield put({
        type: IntegrationActions.SET_LOADING,
        payload: false
      });
    }
  } catch (e) {
    console.log(e);
  }
}

function* createIntegration(action: IAction<any, {type: string}>) {
  try {
    const int = yield Service.create(action.payload.type);
    const {integration: {item}} = yield select();
    yield put({
      type: IntegrationActions.SET_LOADING,
      payload: true
    });
    yield put({
      type: IntegrationActions.SET_ITEM,
      payload: {
        ...item,
        item: int
      }
    });
    yield put({
      type: IntegrationActions.SET_LOADING,
      payload: false
    });
  } catch (e) {

  }
}

export default function* rootIntegrationSaga() {
  yield all([
    yield takeLatest(IntegrationActions.GET_ITEMS, getAll),
    yield takeLatest(IntegrationActions.GET_ITEM, getSingle),
    yield takeLatest(IntegrationActions.GET_LOGS, getLogs),
    yield takeLatest(IntegrationActions.CREATE_INTEGRATION, createIntegration)
  ])
}