import {all, takeLatest, put, select, delay, call} from 'redux-saga/effects';
import {IAction, IActionWithSingleSideEffect, IActionWithErrorAndSuccess} from "../actions";
import service from '../../services/item';
import CompanyService from '../../services/company';
import {ItemsActions} from "../actions/items";
import notify from "@core/utils/notify";
import i18next from "i18next";
import {itemsSelector} from "@core/store/selectors/items";

export function* setItemsLoading(loading: boolean) {
  const { loading: isItemsLoading } = yield select(itemsSelector);
  if(isItemsLoading === loading) return;
  yield put({
    type: ItemsActions.SET_LOADING,
    payload: loading,
  });
}

function* getItems(action: IActionWithSingleSideEffect) {
  try {
    yield setItemsLoading(true);
    yield put({
      type: ItemsActions.SET_ITEM_COUNTERS_DATA_LOADING,
      payload: true,
    });

    const {auth: {user: {company}}} = yield select();
    const items = yield service.getItems(company, action.payload.pagination, action.payload.filters, action.payload.sort);

    const counters = yield service.getCountersData(company, action.payload.filters);
    yield put({
      type: ItemsActions.SET_ITEM_COUNTERS_DATA,
      payload: counters,
    });


    if(action.payload.rewrite) {
      yield put({ type: ItemsActions.CLEAR_ITEMS });
      yield delay(500);
    }

    yield put({
      type: ItemsActions.SET_ITEMS,
      payload: {
        data: items.data,
        total: items.count,
      }
    });
    yield delay(100);
    yield setItemsLoading(false);
    if(action.sideEffect) {
      yield action.sideEffect();
    }
  } catch(E) {
    yield setItemsLoading(false);
    yield put({
      type: ItemsActions.SET_ITEM_COUNTERS_DATA_LOADING,
      payload: false,
    });
    notify.error('Error getting data for table. Please try again later');
  }
}

function* getItemsMobile(action: IActionWithSingleSideEffect) {
  try {
    yield setItemsLoading(true);

    const {auth: {user: {company}}} = yield select();
    const items = yield service.getItems(company, action.payload.pagination, action.payload.filters, action.payload.sort);
    yield delay(500);

    if(action.payload.rewrite) {
      yield put({ type: ItemsActions.CLEAR_ITEMS });
    }
    yield put({
      type: ItemsActions.SET_ITEMS_MOBILE,
      payload: {
        data: items.data,
        total: items.count,
      }
    });
    if(action.sideEffect) {
      yield action.sideEffect();
    }
  } catch(E) {
    notify.error('Error getting data for table. Please try again later');
  }
  yield setItemsLoading(false);
}

function* getItem(action: IActionWithSingleSideEffect) {
  try {
    yield setItemsLoading(true);

    const {auth: {user: {company}}} = yield select();
    let item = yield service.getItem(action.payload.id, company);

    yield put({
      type: ItemsActions.SET_ITEM,
      payload: item
    });
    if(action.sideEffect) {
      action.sideEffect(item);
    }
  } catch(E) {
    notify.error('Ошибка получения ТМЦ. Тмц не найдено или не существует');
  }
  yield setItemsLoading(false);
}

function* getItemTransactions() {
  try {
    yield put({
      type: ItemsActions.SET_TX_LOADING,
      payload: true
    });
    const {items: {txs: {pagination}, item: {_id}}, auth: {user: {company}}} = yield select();

    const txs = yield service.getItemTransactions(_id, company, pagination);
    yield put({
      type: ItemsActions.SET_ITEM_TXS,
      payload: txs
    });
    yield put({
      type: ItemsActions.SET_TX_LOADING,
      payload: false
    });
  } catch(E) {
    console.log(E);
    yield put({
      type: ItemsActions.SET_TX_LOADING,
      payload: false
    });
  }
}

function* getItemTransfers(action: IAction) {
  try {
    const transfers = yield service.getItemTransfers(action.payload.id, action.payload.company);
    yield put({
      type: ItemsActions.UPDATE_ITEM,
      payload: {
        transfers
      }
    });
  } catch(E) {
    console.log(E);
  }
}

function* generateQr(action: IActionWithSingleSideEffect) {
  try {
    const {auth: {user: {company}}} = yield select();
    yield CompanyService.generateCode(action.payload, company);
  } catch (e) {
    notify.error('Error qr code generation')
  }
}
function* downloadCode(action: IActionWithSingleSideEffect) {
  try {
    const {auth: {user: {company}}} = yield select();
    yield service.downloadCode(action.payload.code, action.payload.type, company);
  } catch (e) {
    notify.error('Error qr code downloading')
  }
}

function* uploadPhotos(action: IActionWithErrorAndSuccess) {
  try {
    const {auth: {user: {company}}} = yield select();
    const data = new FormData();
    for(let photo of action.payload.photos) {
      data.append('photos', photo);
    }
    const {data: photos, error} = yield service.uploadPhotos(company, action.payload.id, data);

    yield put({
      type: ItemsActions.UPDATE_ITEM_PHOTOS,
      payload: photos
    });
    action.sideEffectSuccess(error)
  } catch (e) {
    notify.error('Error in files upload');
    action.sideEffectError(e);
  }
}

function* deleteItemPhoto(action: IActionWithSingleSideEffect) {
  try {
    const {auth: {user: {company}}, items: {item}} = yield select();
    yield service.deletePhoto(company, item._id, action.payload.photo);

    const photos = item.photos.filter((photo: any) => photo.name !== action.payload.photo)

    yield put({
      type: ItemsActions.UPDATE_ITEM,
      payload: {
        photos
      }
    })
    notify.success(i18next.t('successfully'))
  } catch (e) {
    notify.warning('Error deleting item photo');
  }
}

function* setMainItemPhoto(action: IActionWithSingleSideEffect) {
  try {
    const {auth: {user: {company}}, items: {item}} = yield select();
    yield service.setMainPhoto(company, item._id, action.payload.photo);

    const sorted = item.photos.sort((a: any, b: any) => {
      if(a.name === action.payload.photo) return -1;
      else if(b.name === action.payload.photo) return 1;
      else return 0
    });

    yield put({
      type: ItemsActions.UPDATE_ITEM,
      payload: {
        photos: sorted
      }
    })

  } catch (e) {
    notify.warning('Error deleting item photo');
  }
}

function* createReport(action: IActionWithSingleSideEffect) {
  try {
    const {auth: {user: {company}}} = yield select();
    yield service.createReport(company, action.payload.email, action.payload.filters, action.payload.sort);

    yield action.sideEffect();
  } catch (e) {
    notify.warning('Error report creation')
  }
}


export { default as itemOperations } from './item-operations';
export { default as itemBulkOperations } from './item-bulk-operations';

export function* rootItemSaga() {
  yield all([
    yield takeLatest(ItemsActions.GET_ITEMS, getItems),
    yield takeLatest(ItemsActions.GET_ITEMS_MOBILE, getItemsMobile),
    yield takeLatest(ItemsActions.GET_ITEM, getItem),
    yield takeLatest(ItemsActions.GET_ITEM_TXS, getItemTransactions),
    yield takeLatest(ItemsActions.GET_ITEM_TRANSFERS, getItemTransfers),
    yield takeLatest(ItemsActions.GENERATE_QR, generateQr),
    yield takeLatest(ItemsActions.DOWNLOAD_CODE, downloadCode),
    yield takeLatest(ItemsActions.UPLOAD_PHOTOS, uploadPhotos),
    yield takeLatest(ItemsActions.DELETE_ITEM_PHOTO, deleteItemPhoto),
    yield takeLatest(ItemsActions.SET_MAIN_ITEM_PHOTO, setMainItemPhoto),
    yield takeLatest(ItemsActions.CREATE_REPORT, createReport),
  ])
}
