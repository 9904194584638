import axios from '../utils/axios';
import {IFiltersPagination} from "@core/types/store";

const int = [
  {
    title: '1C Company',
    description: 'Настройка интеграции с клиенской 1С системой учёта',
    img: '1C.png',
    color: "rgb(247 234 0)",
    type: '1C'
  }
]

export default {
  getAll: async () => (await axios.get('/integration')).data,
  getById: async (id: string, params: IFiltersPagination) => (await axios.get(`/integration/${id}`, {params})).data,
  getAvailableIntegrations: (data: any = []) => {
    const connectedTypes = data.map((i: any) => i.type);
    return int.map(i => ({
      ...i,
      exists: connectedTypes.includes(i.type),
      item: data.find((con: any) => con.type === i.type)
    }))
  },
  getIntegrationByType: (type: string) => (int.find(i => i.type === type) || null),
  create: async (type: string) => (await axios.post('/integration', {type})).data
}